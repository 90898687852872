import { DialogService, DialogSettings } from "aurelia-dialog";
import { inject, autoinject, NewInstance } from "aurelia-framework";
import { AllKpiReportService } from "../../services/AllKpiReportsService";
import { KpiReportSearchTerms } from "models/KpiReportSearchTerms";
import { SignalrWrapper } from "signalrwrapper";
import { AuthService } from "auth-service";
import { BrowserStorage } from "../../utils/browserStorage";
import { ReportingPeriodViewModel } from "models/ReportingPeriodViewModel";
import { ReportingPeriodService } from "services/ReportingPeriodService";
import { ReportingFrequency } from "models/ReportingFrequency";

@inject(
    AllKpiReportService,
    SignalrWrapper,
    AuthService,
    NewInstance.of(BrowserStorage, ["KpiReports"]),
    DialogService)
export class KpiReports {

    private storage: BrowserStorage;
    private searchStorageKey: string = "searchTerms";
    private kpiReportSearchTerms: KpiReportSearchTerms;

    private memberGaugeChart: Chart | undefined;
    private memberGaugeCanvas: HTMLCanvasElement | undefined;

    private amGaugeChart: Chart | undefined;
    private amGaugeCanvas: HTMLCanvasElement | undefined;

    private pmGaugeChart: Chart | undefined;
    private pmGaugeCanvas: HTMLCanvasElement | undefined;

    private bmGaugeChart: Chart | undefined;
    private bmGaugeCanvas: HTMLCanvasElement | undefined;

    private wmGaugeChart: Chart | undefined;
    private wmGaugeCanvas: HTMLCanvasElement | undefined;

    private dmGaugeChart: Chart | undefined;
    private dmGaugeCanvas: HTMLCanvasElement | undefined;

    private reportingPeriodsSub: string[] = ["2022-Yearly Oct 2022 to Sep 2023", "2023-Yearly Oct 2023 to Sep 2024", "2024-Yearly Oct 2024 to Sep 2025 (Active)", "2025-Yearly Oct 2025 to Sep 2026"];
    private selectedReportingPeriodSub = "2024-Yearly Oct 2024 to Sep 2025 (Active)";
    reportingPeriods: ReportingPeriodViewModel[] = [];
    selectedReportingPeriod: ReportingPeriodViewModel;


    isReset = false;
    isChartVisible = false;
    isGeneratingKpi = false;
    signalreventhandlers: any = {};
    constructor(public allReportService: AllKpiReportService,
        public signalr: SignalrWrapper,
        public authService: AuthService,
        storage: BrowserStorage,
        public dialogService: DialogService, public reportingPeriodService: ReportingPeriodService) {
        this.signalreventhandlers = {
        };

        this.storage = storage;
        this.kpiReportSearchTerms = new KpiReportSearchTerms(this.storage.get(this.searchStorageKey))

    }

    async attached() {

        for (const key in this.signalreventhandlers) {
            if (this.signalreventhandlers.hasOwnProperty(key)) {
                this.signalr.on(key, this.signalreventhandlers[key]);
            }
        }

    }

    detached() {

        this.detachAmGaugeChart();

        for (const key in this.signalreventhandlers) {
            if (this.signalreventhandlers.hasOwnProperty(key)) {
                this.signalr.off(key, this.signalreventhandlers[key]);
            }
        }
        
    }

    initializeMemberGaugeChart = () =>{
      const Chart = (window as any).Chart;
      var data = [25, 50, 75, 100];
      var value = this.getRandomNumber(60,70);
      var chartTitle = "Member Increase Target Vs Actual"
      this.memberGaugeChart = new Chart(this.memberGaugeCanvas, this.gaugeConfig(data, value, chartTitle));
    }

    detachMemberGaugeChart = () =>{
        if (this.memberGaugeChart) {
            this.memberGaugeChart.destroy();
        }
    }

    initializeAmGaugeChart = () =>{
        const Chart = (window as any).Chart;
        var data = [25, 50, 75, 100];
        var value = this.getRandomNumber(70, 85);
        var chartTitle = "AM Increase Target Vs Actual"
        this.amGaugeChart = new Chart(this.amGaugeCanvas, this.gaugeConfig(data, value, chartTitle));
    }

    detachAmGaugeChart = () =>{
        if (this.amGaugeChart) {
            this.amGaugeChart.destroy();
        }
    }

    initializePmGaugeChart = () =>{
        const Chart = (window as any).Chart;
        var data = [25, 50, 75, 100];
        var value = this.getRandomNumber(75,95);
        var chartTitle = "PM Increase Target Vs Actual"
        this.pmGaugeChart = new Chart(this.pmGaugeCanvas, this.gaugeConfig(data, value, chartTitle));
    }

    detachPmGaugeChart = () =>{
        if (this.pmGaugeChart) {
            this.pmGaugeChart.destroy();
        }
    }

    initializeBmGaugeChart = () =>{
      const Chart = (window as any).Chart;
      var data = [25, 50, 75, 100];
      var value = this.getRandomNumber(60,80);
      var chartTitle = "BM Increase Target Vs Actual"
      this.bmGaugeChart = new Chart(this.bmGaugeCanvas, this.gaugeConfig(data, value, chartTitle));
    }

    detachBmGaugeChart = () =>{
        if (this.bmGaugeChart) {
            this.bmGaugeChart.destroy();
        }
    }

    initializeWmGaugeChart = () =>{
        const Chart = (window as any).Chart;
        var data = [25, 50, 75, 100];
        var value = this.getRandomNumber(70, 85);
        var chartTitle = "Worker Meeting Target Vs Actual"
        this.wmGaugeChart = new Chart(this.wmGaugeCanvas, this.gaugeConfig(data, value, chartTitle));
    }

    detachWmGaugeChart = () =>{
        if (this.wmGaugeChart) {
            this.wmGaugeChart.destroy();
        }
    }

    initializeDmGaugeChart = () =>{
        const Chart = (window as any).Chart;
        var data = [25, 50, 75, 100];
        var value = this.getRandomNumber(50,70);
        var chartTitle = " Dawah Meeting Target Vs Actual"
        this.dmGaugeChart = new Chart(this.dmGaugeCanvas, this.gaugeConfig(data, value, chartTitle));
    }

    detachDmGaugeChart = () =>{
        if (this.dmGaugeChart) {
            this.dmGaugeChart.destroy();
        }
    }

    initializeAllGaugeCharts = () =>{
        this.initializeMemberGaugeChart();
        this.initializeAmGaugeChart();
        this.initializePmGaugeChart();
        this.initializeBmGaugeChart();
        this.initializeWmGaugeChart();
        this.initializeDmGaugeChart();
    }

    detachAllGaugeCharts = () =>{
        this.detachMemberGaugeChart();
        this.detachAmGaugeChart();
        this.detachPmGaugeChart();
        this.detachBmGaugeChart();
        this.detachWmGaugeChart();
        this.detachDmGaugeChart();
    }

    gaugeConfig = (data, value, chartTitle) =>{

        var config = {
            type: "gauge",
            data: {
              labels: ["Poor", "Fair", "Good", "Very Good"],
              datasets: [
                {
                  label: "",
                  data: data,
                  value: value,
                  minValue: 0,
                  backgroundColor: ["#fc5b5b", "orange", "yellow", "#1c9e46"],//"#5bd97d"
                  borderWidth: 2
                }
              ]
            },
            options: {
              legend: {
                display: false,
                position: "bottom",
                labels: {
                  generateLabels: {}
                }
              },
              responsive: true,
              title: {
                display: true,
                text: chartTitle,
                position: "bottom",
              },
              layout: {
                padding: {
                  bottom: 30
                }
              },
              needle: {
                radiusPercentage: 1,
                widthPercentage: 1,
                lengthPercentage: 60,
                color: "rgba(0, 0, 0, 1)"
              },
              valueLabel: {
                fontSize: 15,
                formatter: function (value, context) {
                  return value + "%";
                },
                color: "white",
                backgroundColor:"#6c7a6e"

              },
              plugins: {
                datalabels: {
                  display: "auto",
                  formatter: function (value, context) {
                    return context.chart.data.labels[context.dataIndex];
                  },
                  color: function (context) {
                    return "#2d2d2e";
                  },
                  font: function (context) {
                    var innerRadius = Math.round(context.chart.innerRadius);
                    var size = Math.round(innerRadius / 8);
          
                    return {
                      weight: "normal",
                      size: size
                    };
                  }
                }
              }
            }
          };
          return config;
    }

   
    async generateKpi(){
        this.initializeAllGaugeCharts();
        this.isChartVisible = true;
        this.storage.set("searchTerms", this.kpiReportSearchTerms);
    }

    async reset() {
        this.isReset = true;
        this.kpiReportSearchTerms.resetToDefault();
        this.detachAllGaugeCharts();
        this.isReset = false;
        this.isChartVisible = false;
        this.selectedReportingPeriodSub = "2024-Yearly Oct 2024 to Sep 2025 (Active)";
    }
    

    get canGenerateKpi() {
        if (this.kpiReportSearchTerms.organization) 
            return true;
    }

    get canReset() {
        if (this.kpiReportSearchTerms.organization) 
            return true;
    }

    selectedOrganizationChanged(newValue, oldValue) {
      this.loadYears();
    }
  

    async loadYears() {
        const allReportingPeriods = await this.reportingPeriodService.getReportingPeriodsToCreatePlan(this.kpiReportSearchTerms.organization);
        this.reportingPeriods = allReportingPeriods.filter(r => r.reportingFrequency === ReportingFrequency.Yearly);
    }

    getRandomNumber(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
}
